//var jQ = jQuery.noConflict();

var sendData = new Array();
var mainPostingStorageKey = 'posting-';
var mainPostingStorageReg = /^(posting-)/;

//use local store
function isLocalStorageAvailable() {
    try {
        return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
        return false;
    }
}

//clear store
function ClearData(reg, popup_id){
    //if(isLocalStorageAvailable()){
        for(var key in localStorage){
                 if (reg.test(key)) {
                     localStorage.removeItem(key);
                 }
        }
        customClosePopup(popup_id);
    //}
}

function ClearField(key_elem){
    localStorage.removeItem(key_elem);
}

//restore local store
function RestoreData(storagekey, reg, popup_id){
    var imgCount = 0;
    for(var key in localStorage){
            if (reg.test(key)) {
                nameElem = key.replace(storagekey, '');
                objectElem =  JSON.parse(localStorage.getItem(key));
				
                switch(objectElem.objType){
                     case 'checkbox':
                        if( jQ('#'+objectElem.objVal).prop('checked') )
                            jQ('#'+objectElem.objVal).prop('checked', false);
                        else
                            jQ('#'+objectElem.objVal).prop('checked', true);
                        jQ('#'+objectElem.objVal).change();
                            break;
                     case 'radio':
                        jQ('#'+objectElem.objVal).prop('checked', true);
                            break;
                     case 'text':
                        jQ('[name^="'+nameElem+'"]').text(objectElem.objVal);
                            break;
                     case 'other':
                            if(!jQ('[name^="'+nameElem+'"]').length && nameElem.indexOf('photo[') != '-1'){
                                    AddImgSlot( nameElem.substring( nameElem.indexOf("[")+1, nameElem.indexOf("]") ) );
                            }
                     case 'select':                         
                        jQ('[name^="'+nameElem+'"]').val(objectElem.objVal);
                        jQ('[name^="'+nameElem+'"]').change();
                            break;
                     case 'img':
                         if( nameElem.indexOf('campus-posting-image') != -1 ){
                             var img_index = ~~nameElem.replace('campus-posting-image','');
                             imgCount++;
                             
                             jQ('.campus-posting-image[index="'+img_index+'"] img').attr('src', objectElem.objVal);                             
                             jQ('.campus-posting-image[index="'+img_index+'"] #campus-posting-click-to-add-photos').attr('href','javascript:Campus_Posting_AddPhoto('+imgCount+')');
                                                          
                         }
                         else{
                             jQ(objectElem.output+' img').attr('src', objectElem.objVal);
                         }
                        break;
                }

                if( (typeof objectElem.objCallbackFun!=="undefined") && objectElem.objCallbackFun.length>0 )
                        window[objectElem.objCallbackFun]();
            }
    }
    
    
    customClosePopup(popup_id);
}
